import React from "react"
import loadable from "@loadable/component"
import _ from "lodash"
import { Layout, RichText, SEO } from "../components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const Commento = loadable(() => import("../components/Commento"))

const BlogTemplate = ({ data }) => {
  const { author, body, date, headerImage, subtitle, summary, title } =
    data.contentfulBlogPost

  return (
    <Layout title={title}>
      <SEO
        description={summary}
        title={title}
        image={_.get(headerImage, "gatsbyImageData.images.fallback.src")}
        script={
          <script
            src={`https://my.hellobar.com/${process.env.GATSBY_HELLO_BAR_ID}.js`}
            type="text/javascript"
            charset="utf-8"
            async="async"
          ></script>
        }
      />
      <section className="blog-section heroless dark">
        <div className="container">
          <div className="eight columns offset-by-two">
            <div>
              <h2>{title}</h2>
              <h3 className="h4">{subtitle}</h3>
            </div>
            <div className="flex align-center justify-center">
              <GatsbyImage
                alt={_.get(headerImage, "title")}
                className="header-image"
                image={getImage(headerImage)}
              />
            </div>
            <h4 className="h4 author-name">
              {_.get(author, "0.name")}, {_.get(author, "0.title")}
            </h4>
            <h5>{date}</h5>
            <RichText document={JSON.parse(body?.raw)} />
            <Commento />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query blogPostQuery($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      author {
        name
        title
      }
      body {
        raw
      }
      date: publishedOn(formatString: "MMM. DD, YYYY")
      headerImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
      slug
      subtitle
      summary
      title
    }
  }
`
